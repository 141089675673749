import { Form, Formik } from "formik";
import { useUser } from "hooks/useAuth";
import React from "react";
import Link from "next/link";
import * as yup from "yup";
import FormItem from "components/form-item";
import { Button, message } from "antd";
import { useLoginMutation } from "generated/graphql";
import fromErrors from "utils/from-errors";
import { CarePulseLogo, NHSLogo } from "components/header";
import UserLoading from "components/user-loading";
import { useRouter } from "next/router";

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function Login() {
  const [mutate, { loading }] = useLoginMutation();
  const router = useRouter();
  const { refresh } = useUser({
    redirectTo: "/",
    redirectIfFound: true,
  });

  function onSubmit(values, { setErrors }) {
    mutate({ variables: { input: values } }).then((res) => {
      const errors = fromErrors(res?.data?.login?.errors);
      setErrors(errors);
      if (errors.nonFieldErrors) {
        message.error(errors.nonFieldErrors);
      }
      const token = res?.data?.login?.token;
      if (token) {
        localStorage.setItem("auth_token", token);
        refresh().then(() => {
          router.push("/");
        });
      }
    });
  }

  return (
    <div className="flex flex-grow w-full absolute top-0 left-0 h-full">
      <div className="hidden md:flex w-1/2 justify-center items-center flex-col">
        <h1 className="text-blue-2 text-4xl">eProcurement</h1>
        <CarePulseLogo />
      </div>
      <div className="flex w-full md:w-1/2 justify-center items-center bg-white">
        <div className="absolute top-0 right-0 m-10">
          <NHSLogo />
        </div>
        <div className="flex flex-col w-8/12">
          <h2 className="text-blue-2 text-4xl">Login</h2>
          <Formik
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{ email: "", password: "" }}
          >
            <Form className="w-full">
              <FormItem label="Email address" name="email" />
              <FormItem label="Password" name="password" type="password" />
              <Button type="primary" htmlType="submit" loading={loading}>
                Sign in
              </Button>
              <p>
                <Link href="/password/reset">
                  <a>Forgot your password?</a>
                </Link>
              </p>
              <div>
                Don't have an account?{" "}
                <Link href="/register">
                  <a>Sign up</a>
                </Link>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
